
import MapFeatureBase from './MapFeatureBase';
import Point from 'ol/geom/Point'
/*****************************
* MapFeatureBase
*
* Base class for showing 
* features on map
*****************************/
export default class MapFeaturePoint extends MapFeatureBase {

  /*****************************
  * constructor
  *
  *****************************/
  constructor(id, featureType, collection, popupTitle) {
      super(id, featureType, collection, popupTitle);
      this.setGeometryName("Point");   
  }
  /*****************************
  * setPoint
  * set coordinates for point
  *****************************/
  setPoint(x,y){
    this.setGeometry(new Point([x,y]).transform("EPSG:4326", "EPSG:3067")); //66.500648, 25.719805
    this.get("collection").push(this);
  }
}
