import Style from 'ol/style/Style';
import Icon from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import MapFeatureLine from './MapFeatureLine';

/*****************************
* MapFeatureBase
*
* Base class for showing 
* features on map
*****************************/
export default class MapFeatureRoute extends MapFeatureLine {

  /*****************************
  * constructor
  *
  *****************************/
  constructor(id, collection, popupTitle) {
      super(id, collection, popupTitle);
      let style = new Style({
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.2)'
        }),
        stroke: new Stroke({
          lineDash: [4],
          color: '#000000',
          width: 2
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#0000ff'
          })
        })
      });
      this.setStyle(style);
  }
}
