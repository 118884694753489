import $ from 'jquery';

/*****************************
* DataLoader
*
* class for downloading data
* by ajax from server
*****************************/
export default class DataLoader {
  /*****************************
  * constructor
  *
  *****************************/
  constructor(url, callback,callbackObj){
    this.url = url;
    this.data = null;
    this.callback = callback;
	  this.callbackObj = callbackObj;
  	}
  
  /*****************************
  * update
  * downloads data from url and 
  * sends it to callback Function
  *****************************/
 update(params){
    var thisObject = this;
    $.ajax({
        url: thisObject.url,
        data:params,
        dataType: "json",
        success: function(data) {
          thisObject.callback.apply(thisObject.callbackObj,[data]);
        },
		error: function (xhr, ajaxOptions, thrownError) {
			//console.log(xhr.status);
			//console.log(thrownError);
		  }
    });
  }
  /*****************************
  * send
  * sends payload to url and 
  * sends response to callback Function
  *****************************/
send(payload){
    var thisObject = this;
    $.ajax({
        url: thisObject.url,
        method: "POST",
        dataType: "json",
        data: payload,       
        success: function(data) {
          thisObject.callback.apply(thisObject.callbackObj,[data]);
        },
		  error: function (xhr, ajaxOptions, thrownError) {
			//console.log(xhr.status);
			//console.log(thrownError);
		  }
    });
    }
}

//this.dataLoader = new DataLoader(url,this.dataCallback,this);
//this.dataLoader.update();

