import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Collection from 'ol/Collection';

/*****************************
* MapLayerVector
*
* class for handling certain features
* on layer map
*****************************/
export default class MapLayerVector extends VectorLayer {

    /*****************************
    * constructor
    *
    *****************************/    
    constructor(name, visibility,zIndex,minZoom,style=null) {
        var featureCollection = new Collection();
        var source = new VectorSource({features: featureCollection});
        super({ 
            title: name,
            visible: visibility,
            minZoom : minZoom,
            zIndex: zIndex,
            declutter: false,
            source: source,
            style: style
        });
        this.featureCollection = featureCollection
        console.log(this.get("title") + " created")
    }
    /*****************************
    * getCollection
    *
    *****************************/    
    getCollection() {
        return this.featureCollection;
    }
}
