export const FeatureTypes = {
IT: {typeId: 1, typeName: 'It', geomType: "Point" },
ROUTE: {typeId: 2, typeName: 'Route', geomType: "LineString" },
PHOTOGRAPH: {typeId: 3, typeName: 'Photograph', geomType: "Point" }
};

export const StyleType = {
	Default: 0,
	Selected: 1
}
