
import XYZBaseMap from './ol/XYZBaseMap';
import MapSettings from './ol/MapSettings';

/*****************************
* BaseMapHandler
*
* class for creating basemap and 
* ol map object
*****************************/
export default class BaseMapHandler {
    /*****************************
    * constructor
    *
    *****************************/    
    constructor() {
        this.baseMap = null;
        this.host = null;
        this.mapSettings = null;
        this.createMap();
 
      let thisObj = this;

    }
    /*****************************
    * createMap
    *
    *****************************/
    createMap(mapDiv = null) {
        var mapSettings = new MapSettings().settings;
        mapSettings.resolutions = [1024,512,256.0,128.0,64.0,32.0,16.0,8.0,4.0];
        mapSettings.restrictedExtent = [59003, 7055132, 953553,7798148];
        mapSettings.initCenterLat = 7443880;
        mapSettings.initCenterLon = 521561;
        mapSettings.opacity = 0.5;
	      if(mapDiv != null){
      	  mapSettings.mapContainer = mapDiv;
      	  }
        var miscSettings = new Object();
  		  miscSettings.featType = "POLYGON";
        this.baseMap = new XYZBaseMap(mapSettings, miscSettings);
        this.mapSettings = mapSettings;

        this.baseMap.showMap();
    }
}
