import {StyleType} from './Constants';

import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Modify from 'ol/interaction/Modify';
import Select from 'ol/interaction/Select';
import MapFeatureFactory from './MapFeatureFactory';
import MapPopup from './MapPopup';

import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

export default class MapFeatureInteraction {

  constructor(mapObject){
    this.mapObject = mapObject;
    this.actionRuler = null;
    this.actionDraw = null;
    this.actionSnap = null;
    this.actionModify = null;
    var _this = this;
    var feat_hit = false;
    this.mapObject.on('pointermove', evt => { //todo.gets features everywhere 
      if (evt.dragging) return;
      feat_hit = _this.mapObject.forEachFeatureAtPixel(
          evt.pixel,
          function (feature, layer) {
            return feature;
          },
          {
            layerFilter: function (layer) {
              return layer.get('title') === 'FlightplanItems';
            },
          }
        );
  
      _this.mapObject.getTargetElement().style.cursor = (feat_hit != undefined ? 'pointer' : '');
      });
}


  /***********************************
  * addBasicInteractions      
  * 
  ************************************/       
  addBasicInteractions(targetLayer,featureType) {
    let targetSource = targetLayer.getSource();
    this.layer = targetLayer;
    this.actionRuler = new Draw({
        source: targetSource,
        type: "LineString"
    });
    //this.actionDraw = new Draw({source: targetSource, type: "Point"});
    this.addInteractionDraw(targetLayer, featureType);
    this.addInteractionSelect();
    this.actionSnap = new Snap({source: targetSource});
    this.actionModify = new Modify({source: targetSource});

    this.mapObject.addInteraction(this.actionDraw);
    this.mapObject.addInteraction(this.actionSelect);
    this.mapObject.addInteraction(this.actionSnap);
    this.mapObject.addInteraction(this.actionModify);   
  }

  /***********************************
  * addInteractionDraw      
  * types: Point, Linestring or polygon
  ************************************/       
//  addInteractionModify(targetLayer, featureType) {
//    this.actionModify = new Modify({source: targetSource});
  //}

  /***********************************
  * addInteractionSelect      
  * types: Point, Linestring or polygon
  ************************************/       
  addInteractionSelect(targetLayer, featureType) {
    this.actionSelect = new Select();
    this.actionSelect.on('select', function(evt){
      if( evt.target.getFeatures().getLength() > 0 )
            {
          evt.target.getFeatures().item(0).setFeatureStyle(StyleType.Selected); 
          }
      /*if(evt.selected.length > 0){
        console.log("selected");
        evt.target.getFeatures().item(0).setFeatureStyle(StyleType.Selected); 
        }
      else if(evt.deselected.length > 0){
        console.log("deselected");
        evt.target.getFeatures().item(0).setFeatureStyle(); 
        }*/
      });


  }

  /***********************************
  * addInteractionDraw      
  * types: Point, Linestring or polygon
  ************************************/       
  addInteractionDraw(targetLayer, featureType) {
    var _this = this;
    this.featureType = featureType;
    //this.actionDraw = new Draw({source: targetLayer.getSource(), type: this.featureType.geomType});
    this.actionDraw = new Draw({type: this.featureType.geomType});

    //event to indicate that new feature has been drawn
    this.actionDraw.on('drawend', function(evt){
      var newFeat = MapFeatureFactory.createFeatureByFeatureType(_this.featureType,targetLayer.getCollection());
      //evt.feature.setStyle(_this.style);

      //show form in popup to save feature attributes
      var feature = evt.feature;
      var coords = feature.getGeometry().getCoordinates();
      newFeat.setGeometry(evt.feature.getGeometry());
      _this.showPopup.apply(_this, [coords,newFeat]); 
      });
    }
  /***********************************
  * addInteractionRuler      
  * 
  ************************************/       
  addInteractionRuler(targetLayer) {
    let targetSource = targetLayer.getSource();
    this.actionRuler = new Draw({
    source: targetSource,
    type: "LineString"
      });
    this.mapObject.addInteraction(this.actionRuler);
    }
  /***********************************
  * removeInteractions      
  * 
  ************************************/       
  removeInteractions() {
    
    if(this.actionDraw != null){
      this.mapObject.removeInteraction(this.actionDraw);
      this.actionDraw = null;
      }
    if(this.actionSnap != null){
      this.mapObject.removeInteraction(this.actionSnap);
      this.actionSnap = null;
      }
    if(this.actionModify != null){
      this.mapObject.removeInteraction(this.actionModify);
      this.actionModify = null;
      }
    if(this.actionRuler != null){
      this.mapObject.removeInteraction(this.actionRuler);
      this.actionRuler = null;
      }
    }

    /***********************************
  * removeInteractions      
  * 
  ************************************/       
  showPopup(coords, feat) {
    this.popup = new MapPopup("FeatureForm", "popup","<div id='popupText'></div>");
    this.mapObject.addOverlay(this.popup);

    this.popup.setPopupText(feat.getPopupForm());
    //$("#popupText").html(feat.getPopupForm());
    if(feat.getGeometryName() == 'Point')
      {
      this.popup.setPosition(coords); 
      }
    else if(feat.getGeometryName() == 'Linestring'){
      this.popup.setPosition(coords[0]);
      }
    else {
        this.popup.setPosition(coords[0][0]);
      }
    var myform = document.getElementById("popupForm");
    myform.addEventListener("submit", feat.save.bind(feat));
    //todo cleaning
    }
}

      
