import {FeatureTypes} from './Constants'
import MapFeatureIt from './MapFeatureIt';
import MapFeatureRoute from './MapFeatureRoute';

export default class MapFeatureFactory{
  constructor(){
  
    }

/*************************************
*
*
**************************************/
  static createFeatureByFeatureType(featureType, collection){
    switch(featureType.typeName) {
      case FeatureTypes.IT.typeName:
        return new MapFeatureIt(null, collection, "It");
        break;
      case FeatureTypes.ROUTE.typeName:
        return new MapFeatureRoute(null, collection, "Route");
        break;
      default:
        // code block
      } 
    }

/*************************************
*
*
**************************************/
  static createFeatureByFeatureTypeId(featureTypeId, collection){
    switch(featureTypeId) {
      case 1:
        return new MapFeatureIt(null, collection, "It");
        break;
      case 2:
        return new MapFeatureRoute(null, collection, "Route");
        break;
      default:
        return undefined;
      }
  }
}
