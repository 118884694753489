import $ from 'jquery';
import Overlay from 'ol/Overlay';

/*****************************
* MapHandler
*
* class for showing popup on 
* map
*****************************/
export default class MapPopup extends Overlay{
  /*****************************
  * constructor
  *
  *****************************/    
  constructor(ovl_id,container_id, innerHTML) {
        var container = document.createElement("div");
        container.id = container_id;
        container.classList = "ol-popup";
        container.innerHTML = '<a href="#" id="popup-close"></a>';
        container.innerHTML += innerHTML;
    super({
        id: ovl_id, 
        element: container,
        positioning: 'bottom-center',
        offset: [0, -24]
        });

    $(document).on('click',"#popup-close",(evt)=>{
      this.setPosition(undefined);
      return false;
      }); //remove overlay?
  }

  /*****************************
  * setPopupText
  *
  *****************************/
  setPopupText(popupText){
    $("#popupText").html(popupText);
    }
}


