import MapFeatureBase from './MapFeatureBase';
import LineString from 'ol/geom/LineString'
import Point from 'ol/geom/Point'
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
/*****************************
* MapFeatureBase
*
* Base class for showing 
* features on map
*****************************/
export default class MapFeatureLine extends MapFeatureBase {

  /*****************************
  * constructor
  *
  *****************************/
  constructor(id, collection, popupTitle) {
      super(id, collection, popupTitle);
      this.setGeometryName("Linestring");

      
      
/*
new Style({
     stroke: new Stroke({
       color: blue,
       width: 3,
     }),
   })
*/


  }

  setFeatureStyle(){
      const blue = [0, 153, 255, 1];
      this.styles = [
      new Style({
       stroke: new Stroke({
         color: blue,
         width: 3,
          }),
        })
         ];
    this.setArrowHead();
    this.setStyle(this.styles);
    }
  /*****************************
  * setPoint
  * set coordinates for point
  *****************************/
  setLinePoints(point_arr){
    this.setGeometry(new LineString(point_arr).transform("EPSG:4326", "EPSG:3067")); //66.500648, 25.719805
    this.get("collection").push(this);
  }
  /*****************************
  * setArrowHead
  * https://openlayers.org/en/latest/examples/line-arrows.html
  *****************************/

  setArrowHead(){
        var thisObj = this;
        var coordArray = this.getGeometry().getCoordinates();
        const dx = coordArray[coordArray.length-1][0] - coordArray[coordArray.length-2][0];
        const dy = coordArray[coordArray.length-1][1] - coordArray[coordArray.length-2][1];
        const rotation = Math.atan2(dy, dx);
        thisObj.styles.push(
          new Style({
            geometry: new Point(coordArray[coordArray.length-1]),
            image: new Icon({
              src: "/assets/arrow_black.png",
              anchor: [0.75, 0.5],
              rotateWithView: true,
              rotation: -rotation,
            }),
          })
        );
/*
        this.getGeometry().forEachSegment(function (start, end) {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);
        // arrows
        thisObj.styles.push(
          new Style({
            geometry: new Point(end),
            image: new Icon({
              src: "/assets/arrow_black.png",
              anchor: [0.75, 0.5],
              rotateWithView: true,
              rotation: -rotation,
            }),
          })
        );
      });
*/
    }
}
