import {FeatureTypes} from './Constants'
import {StyleType} from './Constants';

import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

import MapFeaturePoint from './MapFeaturePoint';


/*****************************
* MapFeatureBase
*
* Base class for showing 
* features on map
*****************************/
export default class MapFeatureIt extends MapFeaturePoint {

  /*****************************
  * constructor
  *
  *****************************/
  constructor(id, collection, popupTitle) {
      super(id, FeatureTypes.IT, collection, popupTitle);
      this.defaultStyle = new Style({
            //geometry : 'point',
            image: new Icon({
                src: "/assets/it2.png",
                size: [46,46],
                displacement: [0,0]
            }),
            zLayer: 100
        });

     this.selectStyle = new Style({
            //geometry : 'point',
            image: new Icon({
                src: "/assets/it2_selected.png",
                size: [46,46],
                displacement: [0,0]
            }),
            zLayer: 100
        });
      

  }

  /*****************************
  * constructor
  *
  *****************************/
  setFeatureStyle(styleType = StyleType.Default){
    if(styleType == StyleType.Selected){
      this.setStyle(this.selectStyle);
      }
    else {
      this.setStyle(this.defaultStyle);
      }
  }

}
