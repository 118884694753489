import Feature from 'ol/Feature';

/*****************************
* MapFeatureBase
*
* Base class for showing 
* features on map
*****************************/
export default class MapFeatureBase extends Feature {

  /*****************************
  * constructor
  *
  *****************************/
  constructor(id, featureType, collection, popupTitle) {
      super();
      //this.setId(id);
      this.setId(Date.now());
      this.set("collection", collection);
      this.set("popupTitle", popupTitle);
      this.set("featureType",featureType);
      //collection.push(this);  
      //var a=0;   
  }

  /*****************************
  * getFeatureType
  *
  *****************************/
  getFeatureType(){
    return this.get("featureType");
  }
  

}
