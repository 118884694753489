
//import './styles/ol_import.scss'
import 'ol/ol.css';
import $ from 'jquery';
import BaseMapHandler from './BaseMapHandler';
import PublicOverlays from './PublicOverlays';


$(document).ready(function() {
    var mapHandler = new BaseMapHandler();
    var publicOverlays = new PublicOverlays(mapHandler.baseMap);
    publicOverlays.createCommunityLayer();
    publicOverlays.createScheduledParkAreasLayer()
    

});
