//XYZ basemap class inherited from MapContainer
import MapContainer from './MapContainer';
import XYZ from 'ol/source/XYZ';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileLayer from 'ol/layer/Tile';

export default class XYZBaseMap extends MapContainer {
    constructor(mapSettings,miscSettings = null) {
        super();
        
        this.createBaseMap(mapSettings,miscSettings);
        let tileGrid = new TileGrid({
            extent: mapSettings.maxExtent,
            resolutions: mapSettings.serveresolutions,
            origin: [mapSettings.maxExtent[0],mapSettings.maxExtent[3]]
        })
          
        let source = new XYZ({
            attributions: mapSettings.copyrightLabel,
            projection: mapSettings.projection,
            urls: mapSettings.mapUrls,
            tileGrid: tileGrid,
            crossOrigin: 'anonymous'
        });
     
        let baseLayer = new TileLayer({
          title: mapSettings.baseLayerName,
          source: source,
          opacity: mapSettings.opacity,
          is_base_layer: true
        });
        
        this.mapObject.addLayer(baseLayer);
    }
}

