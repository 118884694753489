import $ from 'jquery';
import DataLoader from './ol/DataLoader';
import MapLayerVector  from './ol/MapLayerVector';
import MapPopup  from './ol/MapPopup';
import WKT from 'ol/format/WKT';
import {Circle, Fill, Stroke, Style} from 'ol/style';
import Feature from 'ol/Feature';
/*****************************
* PublicOverlays
*
* shows layers on basemap
* by ajax from server
*****************************/
export default class PublicOverlays {
  /*****************************
  * constructor
  *
  *****************************/
  constructor(baseMapObj){
    this.baseMapObj = baseMapObj;
	  //this.community_style = {strokeColor: "black", strokeOpacity: "0.7", strokeWidth: 2, fillOpacity: 0, strokeDashstyle: "longdash"};
	  //this.park_area_style_big = {strokeColor: "red", fillColor: "red", strokeOpacity: "1.0", strokeWidth: 1, fillOpacity: "0.5"};
	  //this.park_area_style_small = {strokeColor: "red", fillColor: "red", strokeOpacity: "1.0", strokeWidth: 5, fillOpacity: "0.5"};
	  this.community_style = [new Style({                    
                                   stroke: new Stroke({
                                     color: "rgba(0, 0, 0, 0.7)",
                                     width: 2,
                                     lineDash: [10,5]
                                      })
                                    })];
	  this.park_area_style_small = new Style({
	                                fill: new Fill({
                                     color: "rgba(255, 0, 0, 0.5)"
                                     }),
                                   stroke: new Stroke({
                                     color: "rgba(255, 0, 0, 1.0)",
                                     width: 5
                                      })
                                    });

 	  this.park_area_style_big = new Style({
                                fill: new Fill({
                                   color: "rgba(255, 0, 0, 0.5)"
                                   }),
                                 stroke: new Stroke({
                                   color: "rgba(255, 0, 0, 1.0)",
                                   width: 1
                                    })
                                  });
                                    
	  var thisObj = this;
	  
	  //events:
	  $("#downLoadNavFile").click(function(){
        thisObj.downLoadNavFile();
    });
    this.popup = new MapPopup("parkAreaPopup", "popup", "<div id='popupText'></div>");
    this.baseMapObj.mapObject.addOverlay(this.popup);
    this.baseMapObj.mapObject.on('singleclick', function (evt) {
        const coordinate = evt.coordinate;
        thisObj.getFeaturesByCoordinate(evt.coordinate)
      });

	  this.baseMapObj.mapObject.on('moveend',(event)=>{
	  //console.log("moveend:" + event.map.getView().getResolution());
	  thisObj.resolution_changed(event.map.getView().getResolution());
	  });
	  

    }
  /*****************************
  * createCommunityLayer
  *
  *****************************/   
	createCommunityLayer(community_id, simplified){
	  var url = "";
    if (community_id === undefined || community_id == null){
      community_id = 0;
      }
    if (simplified == true || simplified === undefined){
    	url = "/get_communities_geometry_simplified";
    	}
    else { 
    	url = "/get_communities_geometry";
    	}
     
		this.community_layer = new MapLayerVector("communities",true,0,-1, this.community_style);
		this.baseMapObj.mapObject.addLayer(this.community_layer);
		var dataLoader = new DataLoader(url,this.communitiesDataResp,this,{cid: community_id});
		dataLoader.update();
    
	}
  /*****************************
  * createScheduledParkAreasLayer
  *
  *****************************/	
	createScheduledParkAreasLayer(){
	  //this.park_area_layer = new OpenLayers.Layer.Vector("current_park_areas");
		
		this.park_area_layer = new MapLayerVector("current_park_areas",true,0,-1, this.park_area_style_big);
		this.baseMapObj.mapObject.addLayer(this.park_area_layer);
		var dataLoader = new DataLoader("/get_current_park_areas_public",this.parkAreasDataResp,this);
		dataLoader.update();
	}
  /*****************************
  * communitiesDataResp
  *
  *****************************/
  communitiesDataResp(data){
    var layer = this.getLayerByName("communities");
    var format = new WKT();
    var collection = layer.getCollection();
    for(let i = 0; i < data.length; i++) {
      var newFeat = new Feature();  
      var geometry  = format.readGeometry(data[i].the_geom);
      newFeat.setId(data[i].id);
      newFeat.setGeometry(geometry);
      collection.push(newFeat);
        }
  }
  /*****************************
  * parkAreasDataResp
  *
  *****************************/
  parkAreasDataResp(data){
    var thisObj = this;
    var layer = this.getLayerByName("current_park_areas");
    var format = new WKT();
    var collection = layer.getCollection();
    for(let i = 0; i < data.length; i++) {
      var style = {};

      if ( data[i].min_diam <= 2200){
        style = thisObj.park_area_style_small;
        }
      else{
        style = thisObj.park_area_style_big;
      }
      var newFeat = new Feature();  
      newFeat.setStyle(style);
      newFeat.setProperties({"min_diam": data[i].min_diam});
      var geometry  = format.readGeometry(data[i].the_geom);
      newFeat.setId(data[i].id);
      newFeat.setGeometry(geometry);
      collection.push(newFeat);
        }
  }
  /*****************************
    * getLayerByName
    *
    *****************************/
    getLayerByName(name) {
        var targetLayer;
        var layers = this.baseMapObj.mapObject.getLayers().getArray();
        for (var i in layers) {
            if (typeof layers[i].get('title') != 'undefined') {
                if ( layers[i].get('title') == name ) {
                    targetLayer = layers[i];
                    break;
                }
            }
        }
        return targetLayer;
  }
  
  /*****************************
  * getLayerByName
  *
  *****************************/
  resolution_changed(new_resolution){
    console.log("resolution_changed:" + new_resolution);
    var thisObj = this;
    var layer = this.baseMapObj.getLayerByName("current_park_areas");
    if(layer == null){
      return;
    }
	  var features = layer.getCollection().getArray();
	  for(var i = 0; i < features.length; i++){
	    if(new_resolution >= 256  && features[i].get('min_diam') <= 2200){
	      features[i].setStyle(thisObj.park_area_style_small);
	      }
      else{
        features[i].setStyle(thisObj.park_area_style_big);
        }
	    }
}
  /*****************************
  * getFeatureByCoordinate
  *
  *****************************/
  getFeaturesByCoordinate(coordinate){
    //remove previous popup
    var dataLoader = new DataLoader("/schedules/scheduled_feat.json",this.clickPontDataResp,this);
		dataLoader.update({x:coordinate[0], y:coordinate[1]});

  }
  
  /*****************************
  * clickPointDataResp
  *
  *****************************/
  clickPontDataResp(data){
      if (data == null)
        {
        return;
        }   
 
    var popupText= "<div style='font-size:.8em'>"
    popupText += "<p><b>Porotyöt meneillään</b>"+"</p>";
    if (data.community_nick_name != null)
      {
      popupText += "<p>" + data.community_nick_name + "</p>";
      }
    popupText += "<p>Alue: " + data.areaName + "<br/>";
    for(var i = 0 ; i < data.schedules.length; i++)
        {
        popupText += "Alkaa: " + data.schedules[i].startTime + "<br/>";
        popupText += "Päättyy: " + data.schedules[i].endTime + "</p>";
        if(data.schedules[i].info != null)
            {
             popupText += "<p>Lisätietoja:" + data.schedules[i].info + "</p>";
            }
        if(data.schedules[i].comment != null)
            {
             popupText += data.schedules[i].comment;
            }
        popupText += "<br/>";
        }
    popupText += "</div>"
    var format = new WKT();
    var geometry = format.readGeometry( data.center);
    var coordinate = geometry.getCoordinates();
    var overlay = this.baseMapObj.mapObject.getOverlayById("parkAreaPopup");
    overlay.setPopupText(popupText);
    overlay.setPosition(coordinate)
  
  }
  /*****************************
  * downLoadNavFile
  *
  *****************************/
  downLoadNavFile()
    {
    //var lang = $('html').attr('lang');
    var url = "/navfile/";   
    //var url = getDomainSubPath();
    url = url + document.getElementById("dlNavFormat").value;
    window.location = url;
    }
}

